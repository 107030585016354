<template>
  <div class="form-control">
    <label for="name">
      <span>{{ label }}</span>
    </label>
    <div class="input-group">
      <span class="icon" :class="prependIcon" v-if="prependIcon"></span>
      <input
        v-bind="$attrs"
        :type="type"
        :id="name"
        :placeholder="placeholder"
        :name="name"
        :value="modelValue"
        @input="onInput"
      />
      <span
        class="icon"
        :class="appendIcon"
        v-if="appendIcon"
        @click="appendClick"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppInput",
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    appendIcon: {
      type: String,
      default: ""
    },
    prependIcon: {
      type: String,
      default: ""
    },
    appendClick: {
      type: Function,
      default: () => {}
    }
  },
  emits: ["update:modelValue"],
  methods: {
    onInput($event) {
      this.$emit("update:modelValue", $event.target.value);
    }
  }
};
</script>

<style scoped></style>
