<template>
  <div class="responsive-bg">
    <div class="bg-image"></div>
  </div>
  <div class="section-girls">
    <div class="container">
      <div class="stats-block">
        <div><span class="blue-text">6177</span> Single Girls</div>
        <div>
          <span class="green-text">{{ userOnlineCount }}</span> Online Now
        </div>
      </div>
      <Preloader class="main-preloader" v-if="!usersRandom.length" />
      <div class="row">
        <div class="col" v-for="user in usersRandom" :key="user.id">
          <AppProfileCard :user="user" />
        </div>
      </div>
      <div class="text-center">
        <a href="#" id="seeAllGirls" @click.prevent="setSignUpModal"
          >See all girls <span class="icon-arrow"></span
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import AppProfileCard from "@/components/AppProfileCard";
import signUpModalMixin from "@/signUpModalMixin";
import { mapGetters } from "vuex";

import Preloader from "@/components/Preloader.vue";

export default {
  name: "Home",
  components: {
    AppProfileCard,
    Preloader
  },
  mixins: [signUpModalMixin],
  computed: {
    ...mapGetters({
      usersRandom: "usersRandom"
    }),
    userOnlineCount() {
      return Math.floor(Math.random() * (421 - 321 + 1)) + 321;
    }
  }
};
</script>

<style lang="scss">
.main-preloader {
  position: relative;
  min-height: 50vh;
}
</style>
