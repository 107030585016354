<template>
  <div class="label-new">
    New
  </div>
</template>

<script>
export default {
  name: "AppLabelNew"
};
</script>

<style scoped></style>
