<template>
  <div
    class="girl-card"
    @click="$router.push({ name: 'ProfileId', params: { id: user.id } })"
  >
    <div class="girl-avatar">
      <AppLabelNew v-if="user.is_new" />
      <img
        v-if="user.photo"
        :src="`//storage.victoriyaclub.com${user.photo}`"
        :alt="user.name"
      />
    </div>
    <div class="girl-name">
      <span class="icon-circle" v-if="user.online"></span>
      <span>{{ user.name }}</span>
      <div class="label-online" v-if="user.online">
        Online
      </div>
    </div>
  </div>
</template>

<script>
import AppLabelNew from "@/components/AppLabelNew";
export default {
  name: "AppProfileCard",
  components: { AppLabelNew },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
