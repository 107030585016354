export default {
  methods: {
    setSignUpModal() {
      document
        .querySelector("body")
        .classList.add("sign-popup", "overflow-hidden");
    },
    closeSignUpModal() {
      document
        .querySelector("body")
        .classList.remove("sign-popup", "overflow-hidden");
    }
  }
};
